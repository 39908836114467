<template>
  <div class="flex-column batchDel_main">
    <pageHead class="messageMainView_head" :title="pageTitle" />
    <van-row class="search_box">
      <van-col span="24">
        <searchBox placeholder="请输入" @input="handleInput" :disabled="false"></searchBox>
      </van-col>
    </van-row>
    <div class="delete-content"
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
      v-infinite-scroll="loadMore">
      <van-checkbox-group
        @change="handleChange"
        v-if="vanSwipeList.length > 0"
        v-model="selectIdList"
        :max="100">
        <van-checkbox
          :disabled="getMemberDisabled(item)"
          checked-color="#FE5800"
          class="delete-item"
          v-for="item in vanSwipeList"
          :key="item.userId"
          :name="item.userId">
          <div class="user-wrap">
            <AvatarFrame class="wrap-left" :user-info="item"></AvatarFrame>
            <div class="wrap-right">
              <div class="flex-row-h-center user-name">
                <span v-html="item.username"></span>
                <van-tag size="medium" v-if="item.identity && item.identity<=2" style="margin-left: 8px;"
                  color="#FE5800" plain>{{ getIdentity(item.identity) }}
                </van-tag>
                <van-tag size="medium" v-if="item.isMuted" style="margin-left: 8px;" color="rgba(109,114,120,0.5)"
                  plain>被禁言
                </van-tag>
              </div>
              <div class="user-dept">{{ item.deptStr }}</div>
            </div>
          </div>
        </van-checkbox>
      </van-checkbox-group>

      <!--  todo    骨架屏-->
      <div class="skeleton-box">
        <van-skeleton v-if="!isLoaded && vanSwipeList.length === 0" title :row="10" />
      </div>

      <!-- 无数据展示 -->
      <template v-if="isLoaded && vanSwipeList.length === 0">
        <div class="hotCircle_noData">
          <div class="noData_box">
            <div class="noData_img"></div>
            <span>{{ noDataInfo.title1 || '暂未数据' }}</span>
          </div>
        </div>
      </template>

      <footer class="footer">
        <div class="flex-row-space-between delete-list">
          <div class="flex-row-h-center left-list">
            <van-badge style="margin-right: 10px" v-for="item in selectList" :key="item.id">
              <van-image
                round
                width="36px"
                height="36px"
                fit="cover"
                position="left"
                :src="item.avatar"
              />
              <template #content>
                <van-icon @click="handleDelete(item)" name="cross" class="badge-icon" />
              </template>
            </van-badge>
          </div>
          <div class="flex-center-row delete-button"
            @click="handleSubmit"
            :style="{backgroundColor: getBtnBgColor()}">
            <span v-if="sourceType === 'addMuted'">确认禁言</span>
            <span v-else-if="sourceType === 'deleteMuted'">解除禁言</span>
            <span v-else>确定删除</span>
            <span v-if="selectList.length > 0">({{ selectList.length }})</span>
          </div>
        </div>
      </footer>
    </div>

  </div>
</template>

<script>
import pageHead from '@/components/pageHead'
import AvatarFrame from '@/components/avatarFrame'
import searchBox from '@/components/searchBox'
import {
  batchAddMemberMuted,
  batchRemoveMember, batchRemoveMemberMuted,
  getListCanMuteMember,
  getListUserForRemove,
  listMutedMember
} from "@/api/circle";
import {CRICLE_IDENTITY} from "@/config/config";
import {setItem} from "@/utils/storage";

export default {
  name: 'batchDel',
  components: {
    pageHead,
    searchBox,
    AvatarFrame
  },
  data () {
    return {
      // 来源页面类型,默认1
      // 1.delete 删除成员
      // 2.addMuted 添加禁言成员
      // 3.deleteMuted 解除禁言成员
      sourceType: 'delete',
      pageTitle: '删除圈成员', // 页面标题
      selectIdList: [],
      finished: false,
      selectList: [], // 选择的数据
      vanSwipeList: [], // 成员列表
      noDataInfo: {
        title1: '暂无相关人员'
      },
      paginationData: {
        circleId: 0,
        pageNum: 1,
        pageSize: 20,
        username: ''
      },
      isClickConfirm: false, // 是否点击确认按钮，点击后没有数据调回上一页
      isLoaded: false,
    }
  },
  created () {
    if (this.$route.query.sourceType) {
      this.sourceType = this.$route.query.sourceType
      this.getPageTitle()
    } else {
      this.sourceType = 'delete'
    }
    if (this.$route.query.circleId) {
      this.paginationData.circleId = this.$route.query.circleId
      this.getMemberList()
    }
  },
  methods: {
    // 获取圈主或管理员
    getIdentity (val) {
      return CRICLE_IDENTITY[`${val}`] || ''
    },
    // 获取页面标题
    getPageTitle () {
      if (this.sourceType === 'addMuted') {
        this.pageTitle = '添加禁言成员'
      } else if (this.sourceType === 'deleteMuted') {
        this.pageTitle = '解除禁言成员'
      } else {
        this.pageTitle = '删除成员'
      }
    },
    // 判断是否需要Disabled
    getMemberDisabled (item) {
      if (this.sourceType === 'addMuted') {
        return item.isShow
      }
    },
    // 获取按钮的背景色
    getBtnBgColor () {
      if (this.sourceType === 'deleteMuted') {
        return '#FE5800'
      } else {
        return '#f42626'
      }
    },
    initData () {
      this.paginationData.pageNum = 1
      this.selectIdList = []
      this.selectList = []
    },
    // 选项发生变化
    handleChange (value) {
      console.log(value)
      console.log(this.selectIdList)
      this.selectList = []
      this.selectIdList.forEach(idItem => {
        this.selectList = this.selectList.concat(this.vanSwipeList.filter(item => item.userId === idItem))
      })
      console.log(this.selectList)
    },
    // 删除不需要删除的某一项
    handleDelete (item) {
      this.selectList = this.selectList.filter(row => row.userId !== item.userId)
      this.selectIdList = this.selectIdList.filter(row => row !== item.userId)
    },
    // 加载更多
    loadMore () {
      if (!this.finished && this.vanSwipeList.length > 0) {
        this.loading = true
        this.paginationData.pageNum++
        this.getMemberList()
      }
    },
    // 分页获取圈成员列表
    async getMemberList (type) {
      try {
        if (!this.paginationData.username) {
          delete this.paginationData.username
        }
        let res
        if (this.sourceType === 'addMuted') {
          res = await getListCanMuteMember(this.paginationData) || {}
          setItem('memberManageCurrentIndex', 1)
        } else if (this.sourceType === 'deleteMuted') {
          res = await listMutedMember(this.paginationData) || {}
          setItem('memberManageCurrentIndex', 1)
        } else {
          res = await getListUserForRemove(this.paginationData) || {}
        }
        const {records} = res || []
        if (this.isClickConfirm) {
          this.$router.go(-1)
        }
        if (records.length < 20) {
          this.finished = true
        }
        const list = records.map(item => {
          return {
            ...item,
            ...item.userBasicVO
          }
        })
        if (type === 'reset') {
          this.vanSwipeList = list
        } else {
          this.vanSwipeList = this.vanSwipeList.concat(list)
        }
        if (this.sourceType === 'delete') {
          this.vanSwipeList = this.vanSwipeList.filter(item => item.identity !== 1)
        }
        console.log(this.vanSwipeList)
      } finally {
        this.isLoaded = true
      }
    },
    // 确认删除
    handleSubmit () {
      if (this.selectIdList.length === 0) {
        return
      }
      this.isClickConfirm = true
      if (this.sourceType === 'addMuted') {
        this.vanSwipeJy()
      } else if (this.sourceType === 'deleteMuted') {
        this.deleteMeted()
      } else {
        this.deleteMember()
      }
    },
    // 删除成员接口
    deleteMember () {
      this.$dialog.confirm({
        title: '删除成员',
        message: '你确定删除这些成员吗？',
      }).then(() => {
        batchRemoveMember({
          userIds: this.selectIdList,
          circleId: this.$route.query.circleId
        }).then(() => {
          this.$toast({
            message: '删除成功',
            position: 'top'
          })
          this.$router.go(-1)
          // this.initData()
          // this.getMemberList('reset')
        }).catch(err => {
          console.log(err)
        })
      })
    },
    // 批量添加禁言
    vanSwipeJy () {
      this.$dialog.confirm({
        title: '禁言成员',
        message: '你确定要禁言该成员吗？禁言后该成员无法在圈内发帖',
        confirmButtonText: '禁言成员',
        cancelButtonText: '再考虑下'
      }).then(() => {
        batchAddMemberMuted({
          userIds: this.selectIdList,
          circleId: this.$route.query.circleId
        }).then(() => {
          this.$toast({
            message: '禁言成功',
            position: 'top'
          })
          this.initData()
          this.getMemberList('reset')
        }).catch(err => {
          // alert(err)
          console.log(err)
        })
      })
    },
    // 解除禁言
    deleteMeted () {
      this.$dialog.confirm({
        title: '解除成员禁言',
        message: '你确定要解除禁言吗？解除后成员可以在圈子发言',
        confirmButtonText: '解除禁言',
        cancelButtonText: '再考虑下'
      }).then(() => {
        batchRemoveMemberMuted({
          userIds: this.selectIdList,
          circleId: this.$route.query.circleId
        }).then(() => {
          this.$toast({
            message: '解除禁言成功',
            position: 'top'
          })
          this.initData()
          this.getMemberList('reset')
        })
      })
    },
    // 回调输入框
    handleInput (value) {
      this.finished = false
      this.paginationData.username = value
      this.paginationData.pageNum = 1
      this.getMemberList('reset')
    },
  }
}
</script>

<style lang="scss" scoped>
.batchDel_main {
  background: #fff;
  height: 100%;

  .delete-content {
    overflow-y: auto;
    flex: 1;
    padding-bottom: 100px;
  }

  .skeleton-box {
    margin-top: 20px;
  }

  .user-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;

    .wrap-left {
      width: 44px;
      height: 44px;
      overflow: hidden;
    }

    .wrap-right {
      line-height: 22px;
      margin-left: 10px;

      .user-name {
        font-size: 14px;
        font-weight: 500;
        text-align: LEFT;
      }

      .user-dept {
        font-size: 14px;
        color: #6d7278;
      }
    }
  }

  .delete-item {
    padding: 16px 0;
    margin: 0 24px;
    border-bottom: 1px solid #f4f4f4;

    .name {
      margin-left: 10px;
      font-size: 14px;
      color: #000000;
    }
  }

  .member-item {
    border-bottom: 1px solid #f4f4f4;

    .left {
      padding: 16px 0;
      margin-left: 24px;

      .check-box {
        width: 21px;
        height: 21px;
        margin-right: 10px;
        box-sizing: border-box;
      }

      .checkbox_icon {
        box-sizing: border-box;
        border-radius: 50%;
        border: 2px solid #dcdcdc;
      }

      .active_icon {
        background: url('~@/assets/imgs/circle/Slice43@2x.png');
        background-size: 100%;
      }
    }

    .name {
      margin-left: 10px;
      font-size: 14px;
      color: #000000;
    }

    .delete-button {
      height: 100%;
    }
  }

  .van_cell_box {
    ::v-deep .van-cell {
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #f4f4f4;
      font-size: 16px;
      color: #000000;
    }
  }

  .circleList {
    overflow: scroll;
    width: 230px;
    float: left;

    .circleLi {
      display: flex;
    }

    .item_cir:first-child {
      margin-left: 0;
    }

    .item_cir {
      margin-right: 5px;

      .cir_title {
        font-size: 12px;
        color: #333333;
        text-indent: 6px;
        margin-top: 6px;
        letter-spacing: 0;
      }

      .van_image_box {
        position: relative;

        .van_image_lsBox {
          position: absolute;
          right: 0;
          z-index: 88;
          top: -10px;

          .van_image_ls {
            display: inline-block;
            width: 14px;
            height: 14px;
            color: #fff;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.75);
            position: relative;

            &::after {
              content: '-';
              position: absolute;
              left: 2px;
              top: -9px;
            }
          }
        }

        img {
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
    }
  }

  .uploader_box {
    padding: 18px 15px 0 15px;
    overflow: hidden;

    .submitAdd {
      display: inline-block;
      width: 108px;
      height: 38px;
      background: #f6f6f6;
      border-radius: 100px;

      font-size: 13px;
      text-align: CENTER;
      color: #6d7278;
      line-height: 35px;
      float: right;
    }

    .submitAdd2 {
      display: inline-block;
      width: 108px;
      height: 38px;
      background: #FE5800;
      border-radius: 100px;
      font-size: 13px;
      text-align: CENTER;
      color: #fff;
      line-height: 35px;
      float: right;
    }
  }

  .search_box {
    padding-top: 12px;

    ::v-deep .van-search__content {
      height: 41px;
      padding-left: 15px;
    }

    ::v-deep .van-cell {
      line-height: 31px;
    }
  }

  ::v-deep .van-search__content {
    border-radius: 70px;
  }

  .cancal {
    position: relative;
    left: 10px;
    top: 3px;

    .cancal_title {
      font-size: 14px;
      text-align: center;
      color: #000000;
      line-height: 14px;
    }
  }

  .vant_List {
    overflow: scroll;

    ::v-deep {
      .van-swipe-cell__wrapper {
        display: flex;
        border-bottom: 1px solid #f4f4f4;
      }

      .van-card__title {
        padding-left: 0;
      }

      .van-card {
        padding-left: 10px;
        padding: 0 0 0 10px;
      }

      .van-card:not(:first-child) {
        margin-top: 0;
      }

      .van-swipe-cell:first-child {
        margin-top: 0;
      }

      .van-swipe-cell {
        margin-top: 16px;
        padding: 0 15px;
      }
    }


  }

  .hotCircle_noData {
    display: flex;
    height: 450px;
    justify-content: center;
    align-items: center;
    text-align: center;

    .noData_box {
      margin-bottom: 16px;

      .noData_img {
        width: 212px;
        height: 114px;
        background: url('~@/assets/imgs/circle/Slice39@2x.png') no-repeat;
        background-size: cover;
        margin: 0 auto;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }

    span {
      font-size: 12px;
      color: #6d7278;
    }
  }

  footer {
    width: 100%;
    height: 108px;
    position: fixed;
    background: #fff;
    border-top: 1px solid #f4f4f4;
    bottom: 0;

    .delete-list {

      padding: 0 16px;

      .left-list {
        flex-wrap: nowrap;
        width: 250px;
        overflow-x: auto;
        padding: 16px 0 0;
      }
    }

    .delete-button {
      width: 108px;
      height: 38px;
      border-radius: 100px;
      font-size: 13px;
      color: #fff;
      margin-top: 16px;
      background-color: #888888;
    }
  }
}
</style>
